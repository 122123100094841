import {
    AuthStore,
    CartStore,
    isSignedIn,
    LoaderStore,
    OverlayStore,
    useGlobalOptions
} from '@corratech/context-provider';
import { TextInputFormik } from '@corratech/form-components';
import { Field, Formik } from 'formik';
import React, { useContext, useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from 'react-apollo';
import { Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { AutoSubmit } from '../AutoSubmit';
import { CheckoutLoginForm } from '../CheckoutLoginForm/CheckoutLoginForm';
import { Field as CorraField } from '../Field';
import './CheckoutAccountForm.less';
import MUTATION_SET_GUEST_EMAIL_ON_CART from './graphql/MUTATION_SET_GUEST_EMAIL_ON_CART.JS';
import QUERY_IS_EMAIL_AVAILABLE from './graphql/QUERY_IS_EMAIL_AVAILABLE';
import { Util } from '@magento/peregrine';
import { RegisterForm } from '@corratech/account-drawer/RegisterForm/RegisterForm';
import { isOrderGroovePresent } from 'UtilPath/orderGroove';
const { BrowserPersistence } = Util;
const storage = new BrowserPersistence();
import { useReCaptchaStoreConfig } from 'ModulesPath/Checkout/useReCaptchaStoreConfig';
import { ReCaptcha } from 'react-recaptcha-v3';
import loadReCaptcha from '@corratech/google-recaptcha-v3/src/loadReCaptcha';

export const CheckoutAccountForm = ({
    setAuthStateIsLoading,
    TooltipIcon,
    passwordAutoComplete,
    setEnteredPassword,
    enteredPassword,
    joinRewards,
    isSubscriptionPaymentError,
    setJoinRewards
}) => {
    const [t] = useTranslation();
    const [email, setEmail] = useState('');
    const { cartState, dispatch } = useContext(CartStore);
    const LoadingIndicator = useContext(LoaderStore);
    const { overlayDispatch } = useContext(OverlayStore);
    const { authState, dispatch: authDispatch } = useContext(AuthStore);
    const [isEmailAvailable, setIsEmailAvailable] = useState(null);
    const isOGPresent = isOrderGroovePresent(cartState);

    const [checkAvailability, { loading }] = useLazyQuery(
        QUERY_IS_EMAIL_AVAILABLE,
        {
            fetchPolicy: 'network-only',
            onCompleted: res => {
                hideErrorMessage();
                authDispatch({
                    type: 'SET_EMAIL_AVAILABILITY',
                    isEmailAvailable: res.isEmailAvailable.is_email_available
                });
                setIsEmailAvailable(res.isEmailAvailable.is_email_available);
                // we need to set email on the cart despite the fact of
                // email availability. The user can refuse to login even if his/her email exists in the back end
                setGuestEmail({
                    variables: {
                        cartId: cartState.cartId,
                        email
                    }
                });
            },
            onError: error => {
                let reCaptchaFailure = false;
                let captchaInvalidMsg = null;
                if (error?.graphQLErrors) {
                    for (var idx = 0; idx < error.graphQLErrors.length; idx++) {
                        if (
                            error.graphQLErrors[idx]?.extensions?.category ===
                            'graphql-recaptcha'
                        ) {
                            captchaInvalidMsg =
                                error.graphQLErrors[idx]?.message;
                            reCaptchaFailure = true;
                        }
                    }
                }

                if (!reCaptchaFailure && isV3PlaceEmailCheckCaptchaEnable) {
                    resetReCaptchaTokenEmailCheck();
                }

                if (captchaInvalidMsg) {
                    setCaptchaInvalidMsg(captchaInvalidMsg);
                }
            }
        }
    );

    //ReCaptcha
    const options = useGlobalOptions();
    const { actionV3PlaceEmailCheck } = useReCaptchaStoreConfig();
    const [captchaInvalidMsg, setCaptchaInvalidMsg] = useState(null);
    const [reCaptchaTokenEmailCheck, setReCaptchaTokenEmailCheck] = useState(
        null
    );

    let recaptchaV3Publickey = '';
    let isV3PlaceEmailCheckCaptchaEnable = null;

    if (options.storeConfig && options.storeConfig.recaptcha_v3_public_key) {
        recaptchaV3Publickey = options.storeConfig.recaptcha_v3_public_key;
    }

    if (actionV3PlaceEmailCheck && actionV3PlaceEmailCheck === 'recaptcha_v3') {
        isV3PlaceEmailCheckCaptchaEnable = true;
    } else {
        isV3PlaceEmailCheckCaptchaEnable = false;
    }

    useEffect(() => {
        if (cartState.cart.email) {
            checkAvailability({
                variables: {
                    email: cartState.cart.email
                },
                context: {
                    headers: {
                        'X-ReCaptcha': reCaptchaTokenEmailCheck,
                        'X-ReCaptcha-Check': true
                    }
                }
            });
            setEmail(cartState.cart.email);

            if (isV3PlaceEmailCheckCaptchaEnable) {
                resetReCaptchaTokenEmailCheck();
            }
        }
    }, []);

    const verifyReCaptchaTokenEmailCheckCallback = token => {
        setReCaptchaTokenEmailCheck(token);
        setInterval(function() {
            resetReCaptchaTokenEmailCheck();
        }, 100 * 1000);
    };

    const resetReCaptchaTokenEmailCheck = () => {
        if (window.grecaptcha !== undefined) {
            window.grecaptcha
                .execute(recaptchaV3Publickey, { action: 'email_check' })
                .then(function(token) {
                    setReCaptchaTokenEmailCheck(token);
                });
        }
    };

    const [setGuestEmail] = useMutation(MUTATION_SET_GUEST_EMAIL_ON_CART, {
        onCompleted: res => {
            //when the guest email is set, update the cart in context with the guest email from server
            dispatch({
                type: 'SET_CART',
                cart: {
                    email: res.setGuestEmailOnCart.cart.email
                }
            });
        }
    });

    const validationSchema = yup.object().shape({
        email: yup
            .string()
            .email(t('Email must be a valid email'))
            .required(t('Email is required'))
    });

    useEffect(() => {
        if (
            (cartState.cart.email &&
                cartState.cart.email !== '' &&
                !isSignedIn() &&
                isV3PlaceEmailCheckCaptchaEnable &&
                reCaptchaTokenEmailCheck) ||
            (isV3PlaceEmailCheckCaptchaEnable === false &&
                reCaptchaTokenEmailCheck !== null)
        ) {
            checkAvailability({
                variables: {
                    email: cartState.cart.email
                },
                context: {
                    headers: {
                        'X-ReCaptcha': reCaptchaTokenEmailCheck,
                        'X-ReCaptcha-Check': true
                    }
                }
            });
            setEmail(cartState.cart.email);
            resetReCaptchaTokenEmailCheck();
        }
    }, []);

    const updateEmail = email => {
        setEmail(email);
        hideErrorMessage();
    };

    const hideErrorMessage = () => {
        setCaptchaInvalidMsg(null);
    };

    /*useEffect(() => {
        if (isSignedIn()) {
            overlayDispatch({ type: 'HIDE' });
        }
    }, [isSignedIn(), authState.token]);*/

    const [isReCaptchaLoaded, setIsReCaptchaLoaded] = useState(false);

    useEffect(() => {
        const scriptLoaded = loadReCaptcha(recaptchaV3Publickey, () => {
            setIsReCaptchaLoaded(true);
        });
        if (!scriptLoaded) {
            setIsReCaptchaLoaded(true);
        }
    }, [recaptchaV3Publickey]);

    if (!isReCaptchaLoaded) {
        return <LoadingIndicator />;
    }

    const renderForm = () => {
        return (
            <div className={'checkout-account-form'}>
                <Formik
                    initialValues={{
                        email: cartState.cart.email || email
                    }}
                    validationSchema={validationSchema}
                    onSubmit={values => {
                        checkAvailability({
                            variables: {
                                email: values.email
                            },
                            context: {
                                headers: {
                                    'X-ReCaptcha': reCaptchaTokenEmailCheck,
                                    'X-ReCaptcha-Check': true
                                }
                            }
                        });
                        setEmail(values.email);
                        if (isV3PlaceEmailCheckCaptchaEnable) {
                            resetReCaptchaTokenEmailCheck();
                        }
                    }}
                >
                    {({ submitForm, values, dirty, isValid }) => (
                        <>
                            <CorraField
                                label={
                                    <h2
                                        className={
                                            'step-title email-id-heading'
                                        }
                                    >
                                        {t('Email')}
                                    </h2>
                                }
                            >
                                <Field
                                    name="email"
                                    component={TextInputFormik}
                                    type="email"
                                    placeholder={'name@email.com'}
                                    autoFocus={true} // this will help to communicate the user that it is a required field
                                />
                                <div className={'wrapping-svg'}>
                                    <OverlayTrigger
                                        placement="top-start"
                                        overlay={props => (
                                            <Tooltip
                                                {...props}
                                                id={`tooltip-account-info`}
                                                show={props.show.toString()}
                                            >
                                                {t(
                                                    "We'll send your order confirmation here."
                                                )}
                                            </Tooltip>
                                        )}
                                    >
                                        {TooltipIcon}
                                    </OverlayTrigger>
                                </div>
                            </CorraField>
                            <div>
                                {isV3PlaceEmailCheckCaptchaEnable &&
                                    captchaInvalidMsg && (
                                        <Alert variant="danger">
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: captchaInvalidMsg
                                                }}
                                            />
                                        </Alert>
                                    )}
                            </div>
                            <AutoSubmit
                                submitForm={submitForm}
                                timeToDebounce={500}
                                dirty={dirty}
                                values={values}
                                isValid={isValid}
                            />
                        </>
                    )}
                </Formik>
                {loading ? (
                    <LoadingIndicator />
                ) : isOGPresent && (
                    isEmailAvailable &&
                    cartState.cart.email &&
                    cartState.cart.email !== '' ? (
                        <>
                            <p className={'note'}>
                                {isSubscriptionPaymentError && t('There has been an error processing your order.' )} <br/>
                                {isOGPresent && t(
                                    'You are required to register to subscribe to any products'
                                )}
                            </p>
                            <RegisterForm
                                enteredEmail={email || cartState.cart.email}
                                updateEmail={updateEmail}
                            />
                        </>
                    ) : (
                        <>
                            <p className={'note'}>
                                {isSubscriptionPaymentError && t('There has been an error processing your order.')} <br/>
                                {isOGPresent && t(
                                    'You are required to login to subscribe to any products.'
                                )}
                                {!cartState.cart.email && !email && t(' Please enter your email above to proceed')}
                            </p>
                        </>
                    )
                )}

                {!isSubscriptionPaymentError && !isOGPresent && ((!cartState.cart.email && !email) || isEmailAvailable) &&
                    (
                        <p className={'note'}>
                            {t(
                                'You can create an account after checkout. With an account, you can speed through checkout using saved payment information and saved shipping and billing addresses.'
                            )}
                        </p>
                    )
                }

                {((cartState.cart.email || email) && !isEmailAvailable) && (
                        <CheckoutLoginForm
                            email={
                                !!email
                                    ? email
                                    : !!cartState.cart.email
                                    ? cartState.cart.email
                                    : null
                            }
                            setAuthStateIsLoading={setAuthStateIsLoading}
                            passwordAutoComplete={passwordAutoComplete}
                            setEnteredPassword={setEnteredPassword}
                            enteredPassword={enteredPassword}
                            joinRewards={joinRewards}
                            setJoinRewards={setJoinRewards}
                        />
                    )
                }
            </div>
        );
    };

    if (isSignedIn()) {
        return (
            <AuthStore.Consumer>
                {value => (
                    <div className={'checkout-account-signed-in step-title'} data-cs-mask>
                        {t('Signed in as ')} {value.authState.user.firstname}.
                    </div>
                )}
            </AuthStore.Consumer>
        );
    } else {
        return (
            <div>
                {isV3PlaceEmailCheckCaptchaEnable && (
                    <ReCaptcha
                        action="email_check"
                        sitekey={recaptchaV3Publickey}
                        verifyCallback={verifyReCaptchaTokenEmailCheckCallback}
                    />
                )}

                {(isV3PlaceEmailCheckCaptchaEnable &&
                    reCaptchaTokenEmailCheck !== null) ||
                isV3PlaceEmailCheckCaptchaEnable === false ? (
                    renderForm()
                ) : (
                    <LoadingIndicator />
                )}
            </div>
        );
    }
};
