import {
    CartStore,
    LoaderStore,
    NotificationValueStore
} from '@corratech/context-provider';
import React, { useContext, useState, useEffect, Fragment } from 'react';
import { useMutation } from 'react-apollo';
import { Alert, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AddressForm } from '../forms';
import setShippingAddressesOnCart from '@corratech/checkout/graphql/setShippingAddressesOnCart.graphql';
import { ShippingMethodForm } from '@corratech/checkout/ShippingMethodForm/ShippingMethodForm';
import '@corratech/checkout/Shipping/Shipping.less';
import { Check as CheckIcon } from 'react-feather';
import { LoqateAddressValidator } from 'ModulesPath/LoqateAddressValidation/LoqateAddressValidator';
import { Error as ErrorIcon } from 'ModulesPath/Icons';
import { OrderGiftMessage } from 'ModulesPath/OrderGiftMessage';

export const ShippingUnAuth = ({
    setShowPaymentForm,
    dataLayerAction,
    TooltipIcon,
    shippingMethodRef,
    invalidShippingMsg,
    warningIcon,
    setInvalidShippingMsg,
    invalidShippingAddress,
    setInvalidShippingAddress
}) => {
    const [t] = useTranslation();
    const { cartState, dispatch } = useContext(CartStore);
    const LoadingIndicator = useContext(LoaderStore);
    const { variant } = useContext(NotificationValueStore);

    const [validate, setValidate] = useState(false);
    const [addressFinalised, setAddressFinalised] = useState(false);

    const [setShippingAddress, { loading }] = useMutation(
        setShippingAddressesOnCart,
        {
            variables: {
                cartId: cartState.cartId
            },
            onCompleted: res => {
                dispatch({
                    type: 'SET_CART',
                    cart: {
                        ...res.setShippingAddressesOnCart.cart
                    }
                });
            }
        }
    );

    return (
        <>
            <h2 className={'step-title shipping-address-heading'}>
                {t('Shipping Address')}
            </h2>

            {variant && (
                <Fragment>
                    {variant.variant === 'success' && (
                        <Alert variant={'success'}>
                            <CheckIcon
                                size={14}
                                strokeWidth={'4'}
                                color={'#000'}
                            />
                            {t(variant.content)}
                        </Alert>
                    )}
                </Fragment>
            )}

            <AddressForm
                initialValues={
                    !!cartState.cart.shipping_addresses &&
                    cartState.cart.shipping_addresses[0]
                }
                TooltipIcon={TooltipIcon}
                onFormSubmit={setShippingAddress}
                isShippingAddress
                invalidShippingAddress={invalidShippingAddress}
                setInvalidShippingAddress={setInvalidShippingAddress}
            />

            <div>
                {loading ? (
                    <LoadingIndicator />
                ) : (
                    !!cartState.cart.shipping_addresses &&
                    !!cartState.cart.shipping_addresses[0] && (
                        <ShippingMethodForm
                            shippingMsg={null}
                            warningIcon={warningIcon}
                            shippingMethodRef={shippingMethodRef}
                            invalidShippingMsg={invalidShippingMsg}
                            setInvalidShippingMsg={setInvalidShippingMsg}
                        />
                    )
                )}
            </div>

            <OrderGiftMessage />

            <div className={'step-btn-block next-btn-block'}>
                <Button
                    size="lg"
                    variant="primary"
                    className="next-button"
                    key={'next-button'}
                    onClick={() => {
                        // setShowPaymentForm(true);
                        setAddressFinalised(prevState => !prevState);
                        setValidate(true);
                    }}
                    disabled={
                        !(
                            !!cartState.cart.shipping_addresses &&
                            !!cartState.cart.shipping_addresses[0] &&
                            !!cartState.cart.shipping_addresses[0]
                                .selected_shipping_method &&
                            !!cartState.cart.email
                        ) ||
                        invalidShippingAddress ||
                        cartState.cart.requireGiftMessage
                    }
                >
                    {t('Next')}
                </Button>
            </div>

            {validate && (
                <LoqateAddressValidator
                    setAddressFinalised={setAddressFinalised}
                    setShowPaymentForm={setShowPaymentForm}
                    addressFinalised={addressFinalised}
                />
            )}
        </>
    );
};
