import {
    AuthStore,
    CartStore,
    LoaderStore,
    NotificationValueStore,
    getUserDetails
} from '@corratech/context-provider';
import React, { useContext, useEffect, useState, Fragment } from 'react';
import { useMutation } from 'react-apollo';
import { Button, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AddressBlock } from '../AddressBlock';
import { AddressForm } from '../forms';
import setShippingAddressesOnCart from '../graphql/setShippingAddressesOnCart.graphql';
import setShippingAddressesFromExisting from '../graphql/setShippingAddressFromExisting.graphql';
import { ShippingMethodForm } from '../ShippingMethodForm/ShippingMethodForm';
import './Shipping.less';
import {
    isTwoAddressesEqualByArrayOfKeys,
    arrayOfKeysToCompareAddresses
} from '../util/isTwoAddressesEqualByArrayOfKeys';
import { AlertTriangle as Warning, Check as CheckIcon } from 'react-feather';
import { OrderGiftMessage } from 'ModulesPath/OrderGiftMessage';

export const ShippingAuth = ({
    setShowPaymentForm,
    dataLayerAction,
    dropShip,
    addressMsg,
    shippingMsg,
    warningIcon,
    noAddressForm,
    TooltipIcon,
    shippingMethodRef,
    invalidShippingMsg,
    setInvalidShippingMsg
}) => {
    const [t] = useTranslation();
    const { cartState, dispatch } = useContext(CartStore);
    const { authState, dispatch: dispatchUser } = useContext(AuthStore);
    const LoadingIndicator = useContext(LoaderStore);
    const { variant } = useContext(NotificationValueStore);
    // to set a flag if in the editing mode
    const [editingAddress, setEditingAddress] = useState(true);
    // filteredUserAddresses will set in the useEffect below after removing duplicate addresses
    const [filteredUserAddresses, setFilteredUserAddresses] = useState([]);
    const [currentAddressId, setCurrentAddressId] = useState(null);

    useEffect(() => {
        if (variant) {
            getUserDetails(dispatchUser);
        }
    }, [variant]);

    // this mutation is used if the user submits a new address during the checkout process
    const [
        setShippingAddress,
        { loading: loadingSetShippingAddress }
    ] = useMutation(setShippingAddressesOnCart, {
        variables: {
            cartId: cartState.cartId
        },
        onCompleted: res => {
            dispatch({
                type: 'SET_CART',
                cart: {
                    authNewShippingAddress:
                        res.setShippingAddressesOnCart.cart
                            .shipping_addresses[0],
                    ...res.setShippingAddressesOnCart.cart
                }
            });

            setEditingAddress(false);
        }
    });

    // this mutation is used if the user selects address from the list of available authState.user.addresses
    const [pickAddress, { loading: loadingPickAddress }] = useMutation(
        setShippingAddressesFromExisting,
        {
            variables: {
                cartId: cartState.cartId
            },
            onCompleted: res => {
                dispatch({
                    type: 'SET_CART',
                    cart: {
                        ...res.setShippingAddressesOnCart.cart
                    }
                });
            }
        }
    );

    const setShippingAddressOnCartUsingDefaultUserShippingAddress = () => {
        const defaultShippingAddress = authState.user.addresses.find(
            address => address.default_shipping === true
        );
        if (!!defaultShippingAddress) {
            pickAddress({
                variables: {
                    addressId: defaultShippingAddress.id
                }
            });
        }
    };

    // fire on auth state change
    useEffect(() => {
        if (!!authState.user.addresses && authState.user.addresses.length > 0) {
            setEditingAddress(false);
            if (!!!cartState.cart.shipping_addresses) {
                setShippingAddressOnCartUsingDefaultUserShippingAddress();
            }
        }
    }, [authState.user.addresses, cartState.cart.authenticated]);

    // fire on mount
    useEffect(() => {
        if (!!authState.user.addresses && authState.user.addresses.length > 0) {
            setEditingAddress(false);
            if (
                !!cartState.cart.shipping_addresses &&
                cartState.cart.shipping_addresses.length === 0
            ) {
                setShippingAddressOnCartUsingDefaultUserShippingAddress();
            }
        }
    }, []);

    useEffect(() => {
        if (
            !!cartState.cart.shipping_addresses &&
            !!cartState.cart.shipping_addresses[0]
        ) {
            setEditingAddress(false);
        }
    }, [cartState.cart]);

    // fire this hook to remove duplicate addresses
    // Why we need to do it? Everytime the user submits a new address with the save address flag set to true
    // we set it on the cart as a shipping address and sometimes also as a billing address as well
    // Magento stores all these addresses and then returns all of them. We use them as authState.user.addresses
    // Magento does not distinqushes between shipping and billing addresses that is why we have
    // duplicate addresses which we need to clean up here
    useEffect(() => {
        if (!!authState.user.addresses && authState.user.addresses.length > 0) {
            const indexOfDefaultShippingAddress = authState.user.addresses.findIndex(
                address => address.default_shipping === true
            );

            if (indexOfDefaultShippingAddress > -1) {
                const defaultShippingAddress =
                    authState.user.addresses[indexOfDefaultShippingAddress];
                // we move default shipping address to the first index of authState.user.addresses
                // to make sure that default shipping address will always be present in filteredArr
                // where filteredArr === filteredUserAddresses
                authState.user.addresses.splice(
                    indexOfDefaultShippingAddress,
                    1
                );
                authState.user.addresses.unshift(defaultShippingAddress);
            }
            const filteredArr = authState.user.addresses.reduce(
                (acc, current) => {
                    const x = acc.find(item => {
                        return isTwoAddressesEqualByArrayOfKeys(
                            item,
                            current,
                            arrayOfKeysToCompareAddresses,
                            true
                        );
                    });

                    if (!x) {
                        return acc.concat([current]);
                    } else {
                        return acc;
                    }
                },
                []
            );

            setFilteredUserAddresses(filteredArr);
        }
    }, [authState.user]);

    return (
        <>
            <h2 className={'step-title shipping-address-heading'}>
                {t('Shipping Address')}
            </h2>

            {variant && (
                <Fragment>
                    {variant.variant === 'success' && (
                        <Alert variant={'success'}>
                            <CheckIcon
                                size={14}
                                strokeWidth={'4'}
                                color={'#000'}
                            />
                            {t(variant.content)}
                        </Alert>
                    )}
                </Fragment>
            )}

            {addressMsg && (
                <Alert variant={'warning'}>
                    {warningIcon ? (
                        warningIcon
                    ) : (
                        <Warning size={14} strokeWidth={'4'} color={'#000'} />
                    )}
                    {t(addressMsg)}
                </Alert>
            )}

            {editingAddress && !noAddressForm && (
                <AddressForm
                    initialValues={
                        !!cartState.cart.authNewShippingAddress &&
                        cartState.cart.authNewShippingAddress
                    }
                    onFormSubmit={setShippingAddress}
                    setEditingAddress={setEditingAddress}
                    authMode
                    autoSubmitOff
                    dropShip={dropShip}
                    TooltipIcon={TooltipIcon}
                />
            )}
            {!editingAddress && (
                <div className="address-block-group">
                    {filteredUserAddresses.length > 0 &&
                        filteredUserAddresses.map((address, idx) => (
                            <div
                                key={idx}
                                className={`col ${
                                    isTwoAddressesEqualByArrayOfKeys(
                                        address,
                                        !!cartState.cart.shipping_addresses &&
                                            !!cartState.cart
                                                .shipping_addresses[0]
                                            ? cartState.cart
                                                  .shipping_addresses[0]
                                            : {},
                                        arrayOfKeysToCompareAddresses
                                    )
                                        ? 'address-block-active'
                                        : ''
                                }`}
                            >
                                <AddressBlock key={idx} address={address} />
                                {!isTwoAddressesEqualByArrayOfKeys(
                                    address,
                                    !!cartState.cart.shipping_addresses &&
                                        !!cartState.cart.shipping_addresses[0]
                                        ? cartState.cart.shipping_addresses[0]
                                        : {},
                                    arrayOfKeysToCompareAddresses
                                ) && (
                                    <Button
                                        variant="secondary"
                                        disabled={
                                            loadingPickAddress &&
                                            currentAddressId === address.id
                                        }
                                        onClick={() => {
                                            pickAddress({
                                                variables: {
                                                    addressId: address.id
                                                }
                                            });
                                            setCurrentAddressId(address.id);
                                        }}
                                    >
                                        {t('Ship here')}
                                    </Button>
                                )}
                            </div>
                        ))}
                    {!!cartState.cart.authNewShippingAddress && (
                        <div
                            className={`col ${
                                isTwoAddressesEqualByArrayOfKeys(
                                    cartState.cart.authNewShippingAddress,
                                    !!cartState.cart.shipping_addresses
                                        ? cartState.cart.shipping_addresses[0]
                                        : {},
                                    arrayOfKeysToCompareAddresses,
                                    true
                                )
                                    ? 'address-block-active'
                                    : ''
                            }`}
                        >
                            <AddressBlock
                                address={cartState.cart.authNewShippingAddress}
                                onClick={() => {
                                    setEditingAddress(true);
                                }}
                                action={t('Edit')}
                            />
                            {!isTwoAddressesEqualByArrayOfKeys(
                                cartState.cart.authNewShippingAddress,
                                !!cartState.cart.shipping_addresses[0]
                                    ? cartState.cart.shipping_addresses[0]
                                    : {},
                                arrayOfKeysToCompareAddresses,
                                true
                            ) && (
                                <Button
                                    variant="secondary"
                                    disabled={loadingSetShippingAddress}
                                    onClick={() => {
                                        setShippingAddress({
                                            variables: {
                                                ...cartState.cart
                                                    .authNewShippingAddress,
                                                region:
                                                    cartState.cart
                                                        .authNewShippingAddress
                                                        .region.code,
                                                countryCode:
                                                    cartState.cart
                                                        .authNewShippingAddress
                                                        .country.code
                                            }
                                        });
                                    }}
                                >
                                    {t('Ship here')}
                                </Button>
                            )}
                        </div>
                    )}
                </div>
            )}

            {editingAddress ? null : !!cartState.cart
                  .authNewShippingAddress ? null : (
                <div
                    className={
                        dropShip
                            ? 'new-address-button-container drop-ship'
                            : 'new-address-button-container'
                    }
                >
                    <Button
                        size="lg"
                        variant="primary"
                        onClick={() => setEditingAddress(true)}
                    >
                        {dropShip
                            ? t('+ Drop-ship Address')
                            : t('+ New Address')}
                    </Button>
                </div>
            )}

            <div>
                {loadingPickAddress || loadingSetShippingAddress ? (
                    <LoadingIndicator />
                ) : (
                    !!cartState.cart.shipping_addresses &&
                    !!cartState.cart.shipping_addresses[0] && (
                        <ShippingMethodForm
                            shippingMsg={shippingMsg}
                            warningIcon={warningIcon}
                            shippingMethodRef={shippingMethodRef}
                            invalidShippingMsg={invalidShippingMsg}
                            setInvalidShippingMsg={setInvalidShippingMsg}
                        />
                    )
                )}
            </div>

            <OrderGiftMessage />

            <div className={'step-btn-block next-btn-block'}>
                <Button
                    size="lg"
                    variant="primary"
                    className="next-button"
                    key={'next-button'}
                    onClick={() => {
                        setShowPaymentForm(true);
                    }}
                    disabled={
                        !(
                            !editingAddress &&
                            !!cartState.cart.shipping_addresses &&
                            !!cartState.cart.shipping_addresses[0] &&
                            !!cartState.cart.shipping_addresses[0]
                                .selected_shipping_method &&
                            !!authState.user
                        ) || cartState.cart.requireGiftMessage
                    }
                >
                    {t('Next')}
                </Button>
            </div>
        </>
    );
};
